<template>
  <div class="bar-section" v-if="hideButton">
    <div class="close-item" @click="hideButton = false">
      <i class="pi pi-times"></i>
    </div>
    <h2 class="title">{{ $t('generalPrag') }}</h2>
    <Button outlined @click="scrollDown" :label="$t('startButtonJourney')" class="buttonBanner-button" />
  </div>
  <div class="general-inquiry-box" ref="general_inquiry">
    <div class="title-box">
      <h2 class="title">{{ $t('generalInquiryTitle') }}</h2>
    </div>
    <GeneralInquiryForm :inputWidth="true" />
  </div>
</template>

<script></script>
<style lang="scss">
.buttonBanner-button {
  color: #fff !important;
  border-color: #fff !important;
  background-color: transparent !important;
  box-shadow: none !important ;
}
</style>

<style lang="scss" scoped>
.bar-section {
  width: 100%;
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  text-align: center;
  box-shadow: 0px -5px 8px 0px #144f6f40;
  background: linear-gradient(90deg, #144d6d 0%, #0284c7 50.03%);
  padding: 24px 70px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-direction: column;
  @include media(992) {
    & {
      display: flex !important;
    }
  }
  .close-item {
    position: absolute;
    top: 20%;
    cursor: pointer;

    @include dir(rtl) {
      left: 40px;
    }
    @include dir(ltr) {
      right: 40px;
    }

    i {
      color: $color-white;
      font-size: 28px;
      @include hover(0.2) {
        color: $color-gray-dark;
      }
    }
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
  }
}
.general-inquiry-box {
  box-shadow: 0px 4px 10px 0px #00000026;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  margin-top: 100px;

  .title-box {
    .title {
      background-color: $color-primary;
      padding: 24px 20px;
      box-shadow: 0px 4px 10px 0px #0369a140;
      border-radius: 10px;
      color: $color-white;
      font-size: 20px;
      font-weight: 800;
    }
    .prag {
      padding: 12px 0;
    }
  }
}
</style>

<style lang="scss">
.input-box {
  .phone-item {
    .p-dropdown-trigger {
      width: 2rem;
    }
    .p-dropdown {
      .p-dropdown-label {
        padding: 12px 6px;
      }
    }
  }
}
</style>
